import React, { Component } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  CompanyID,
  isEmpty,
  isSingleSelect,
  uploadFile,
  removeFile,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";

var module = "clientpanel/food-type/";
var moduleName = "Food Type";
var modulePath = "/clientpanel/catalog-food-type";

class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (
      this.props.match.path === "/clientpanel/catalog-food-type/edit/:EditID"
    ) {
      editID = this.props.match.params.EditID;
    }

    this.state = {
      editID: editID,
      pageloading: false,
      postData: {
        name: "",
        status: [],
        action: "add",
        image: "",	
      },
      btndisabled: false,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (this.state.editID !== "") {
      var params = {
        params: "edit_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "ok") {
          showAlert("Success", errMsg, "success", "No");
          var history = this.props.history;
          setTimeout(function () {
            history.push(modulePath);
          }, 1000);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var product_status = result.status === "1" ? "Active" : "In Active";
          var status =
            result.status !== "" && result.status !== null
              ? {
                  label: product_status,
                  value: result.status,
                }
              : "";

          var clientupdatedata = {
            name: result.name,
            status: status,
            action: "edit",
            image: result.image,
          };
          this.setState({ postData: clientupdatedata, pageloading: false });
        } else {
          this.setState({ pageloading: false });
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid Type Food", "error");
        }
      });
    }
  }

  sateValChange = (field, value) => {
    this.setState({ btndisabled: value });
  };

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { postData: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.postData;

    var postObject = {
      name: postData.name,
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : 1,
      action: postData.action,
      image: postData.image,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"catalog-food-type"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <PostForm
                  {...this.props}
                  fields={this.state.postData}
                  btndisabled={this.state.btndisabled}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  sateValChange={this.sateValChange}
                  error_msg={this.state.error_msg}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

function validationConfig(props) {
  const { name, status } = props.fields;

  return {
    fields: ["name", "status", "image"],

    validations: {
      name: [[isEmpty, name]],
      status: [[isSingleSelect, status]],
      image: [[isEmpty, name]],
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  async uplaodFiles(imageType) {
    this.props.sateValChange("btndisabled", true);
    showLoader("ImageDetails");
    showLoader("ImageContainer");
    var imagefile = document.querySelector("#" + imageType);
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "food-type", "client");
    if (fileDetail.status === "ok") {
      this.props.onChange(imageType, fileDetail.url);
    } else {
      showAlert("Error", fileDetail.msg, "error", "No");
    }
    hideLoader("ImageContainer");
    hideLoader("ImageDetails");
    $("#" + imageType).val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeImage(fileName, imageType) {
    showLoader("ImageDetails", "class");

    const [folderName, fileNameRemove] = fileName.split("/").slice(-2);
    const fullName = `${folderName}/${fileNameRemove}`;

    const { status } = await removeFile(fullName, "client");

    if (status === "ok") {
      hideLoader("ImageDetails", "class");
      this.props.onChange(imageType, "");
    }
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFoodTypeName,
      errMsgStatus,
      errMsgImage = "";
    if ($validation.name.error.reason !== undefined) {
      errMsgFoodTypeName = $validation.name.show && (
        <span className="error">{$validation.name.error.reason}</span>
      );
    }

    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    if ($validation.status.error.reason !== undefined) {
      errMsgImage = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="row g-3">
          <div
            className="accordion mt-3 accordion-header-primary"
            id="accordionStyle1"
          >
            <div className="accordion-item active">
              <div
                id="accordionStyle1-3"
                className="accordion-collapse collapse show  mt-3"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgFoodTypeName !== "" &&
                            errMsgFoodTypeName !== false &&
                            errMsgFoodTypeName !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="name"
                          value={fields.name}
                          {...$field("name", (e) =>
                            onChange("name", e.target.value)
                          )}
                          autoComplete="off"
                        />
                        <label htmlFor="name" className="z-0">
                          Food Type Name <span className="error">*</span>
                        </label>
                        {errMsgFoodTypeName}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className={
                          errMsgStatus !== "" &&
                          errMsgStatus !== false &&
                          errMsgStatus !== undefined
                            ? "error-select error"
                            : ""
                        }
                      >
                        <div className="form-floating form-floating-outline custm-select-box mb-4">
                          <Select
                            value={fields.status}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "status"
                            )}
                            placeholder="Select Status"
                            options={[
                              { value: 1, label: "Active" },
                              { value: 0, label: "In Active" },
                            ]}
                            isClearable={true}
                          />
                          <label className="select-box-label">Status</label>
                          {errMsgStatus}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <div className="mb-3" id="ImageContainer">
                          <label htmlFor="formFile" className="form-label">
                            Food Type Image
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="image"
                            onChange={(event) => {
                              this.uplaodFiles("image", event);
                              return false;
                            }}
                          />
                        </div>
                      </div>
                      {fields.image !== "" && fields.image !== undefined && (
                        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                          <div className="dz-details" id="ImageDetails">
                            <div className="dz-thumbnail">
                              <img alt="" src={fields.image} />
                            </div>
                          </div>
                          <a
                            className="dz-remove"
                            href={void 0}
                            onClick={this.removeImage.bind(
                              this,
                              fields.image,
                              "image"
                            )}
                          >
                            Remove file
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="pt-1 pb-4 pr-2 text-end"
            style={{ paddingRight: "20px" }}
          >
            <button
              type="button"
              disabled={this.props.btndisabled}
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                if (!this.props.btndisabled) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
