import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl } from "../../Helpers/Config";
import {
  hideLoader,
  showAlert,
  CompanyID,
  showPriceValue,
  showDateTime,
  addressFormat,
  isEmptyData,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";

var module = "clientpanel/orders/";
var moduleName = "Order Details";
var modulePath = "/clientpanel/order/";

const statusClassMap = {
  1: "primary",
  2: "warning",
  3: "info",
  4: "success",
  5: "danger",
};

class Details extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/order/:orderType/:orderID") {
      editID = this.props.match.params.orderID;
    }
    var order_type = "";
    if (
      !isEmptyData(this.props.match.params) &&
      !isEmptyData(this.props.match.params.orderType)
    ) {
      order_type = this.props.match.params.orderType;
    }
    this.state = {
      editID: editID,
      order_type: order_type,
      order_details: {},
      pageloading: true,
    };
  }
  componentDidMount() {
    if (!isEmptyData(this.state.editID)) {
      var params = {
        params: "company_id=" + CompanyID() + "&detail_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.props.getDetailData(params);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.formpost !== nextProps.formpost) {
      if (nextProps.formpost.length > 0) {
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          var history = this.props.history;
          setTimeout(function () {
            history.push(modulePath + this.state.order_type);
          }, 1000);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          this.setState({ order_details: result });
        } else {
          this.setState({ pageloading: false });
          showAlert("Error", "Invalid Product", "error");
        }
      });
    }
    hideLoader("submit_frm", "class");
    this.setState({ pageloading: false });
  }

  render() {
    if (this.state.pageloading) return <PageLoader />;
    const order = this.state.order_details;
    const customer = order?.customer || {};

    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={this.state.order_type} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath + this.state.order_type}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="card invoice-preview-card">
                  <ul className="list-group fw-bold">
                    <li
                      className={`list-group-item text-end list-group-item-${
                        statusClassMap[order.order_status] || ""
                      }`}
                    >
                      {order?.status_name}
                    </li>
                  </ul>
                  <div className="card-body">
                    <div className="d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column">
                      <div className="mb-xl-0 pb-3">
                        <div className="d-flex svg-illustration align-items-center gap-2 mb-4">
                          <span className="h4 mb-0 app-brand-text fw-bold">
                            {order?.order_availability_name}
                          </span>
                        </div>
                        <p className="mb-1">{customer?.customer_name}</p>
                        <p className="mb-1">
                          <a href={"mailto:" + customer?.order_customer_email}>
                            {customer?.order_customer_email}
                          </a>
                        </p>
                        <p className="mb-0">
                          <a href={"tel:" + customer?.order_customer_email}>
                            {customer?.order_customer_mobile_no}
                          </a>
                        </p>
                        <p className="mb-0">
                          <span className="fw-semibold">Order Source: </span>{" "}
                          {order?.order_source}
                        </p>
                        <p className="mb-0">
                          <span className="fw-semibold">Order Status: </span>{" "}
                          {order?.status_name}
                        </p>
                        <p className="mb-0">
                          <span className="fw-semibold">Cutlery: </span>{" "}
                          {order?.order_cutlery}
                        </p>
                      </div>
                      <div>
                        <h5 className="fw-bold">
                          Order Number #{order?.order_local_no}
                        </h5>
                        <div className="mb-1">
                          <span>{order?.order_availability_name} Date: </span>
                          <span className="fw-bold">
                            {showDateTime(order?.order_date)}
                          </span>
                        </div>
                        <div>
                          <span>Order Created Date: </span>
                          <span className="fw-bold">
                            {showDateTime(order?.order_created_on)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="my-3">
                        <h6 className="pb-2 fw-bold">Delivery Address:</h6>
                        <p className="mb-1">{customer?.customer_name}</p>
                        <p className="mb-1">
                          {addressFormat(
                            customer?.order_customer_unit_no1,
                            customer?.order_customer_unit_no2,
                            customer?.order_customer_address_line1,
                            customer?.order_customer_address_line2,
                            customer?.order_customer_postal_code,
                            customer?.order_customer_country
                          )}
                          {!isEmptyData(customer?.addressRemarks) && (
                            <>
                              <br />({customer?.addressRemarks})
                            </>
                          )}
                        </p>
                      </div>
                      {order?.order_availability_name === "Delivery" && (
                        <div className="my-3">
                          <h6 className="pb-2 fw-bold">Bill Address:</h6>
                          <p className="mb-1">
                            {addressFormat(
                              customer?.order_customer_billing_unit_no1,
                              customer?.order_customer_billing_unit_no2,
                              customer?.order_customer_billing_address_line1,
                              customer?.order_customer_billing_address_line2,
                              customer?.order_customer_billing_postal_code,
                              customer.order_customer_country
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="my-3">
                      <h6 className="pb-2 fw-bold">Rider Details:</h6>
                      <p className="mb-1">
                        <b>Driver Reference Number :</b>{" "}
                        {!isEmptyData(order.order_driver_ref_id)
                          ? order.order_driver_ref_id
                          : "N/A"}
                      </p>
                      <p className="mb-1">
                        <b>Driver Name :</b>{" "}
                        {!isEmptyData(
                          order?.delivery_order_details?.track?.courier?.name
                        )
                          ? order?.delivery_order_details?.track?.courier?.name
                          : "N/A"}
                      </p>
                      <p className="mb-1">
                        <b>Driver Phone :</b>{" "}
                        {!isEmptyData(
                          order?.delivery_order_details?.track?.courier?.phone
                        )
                          ? order?.delivery_order_details?.track?.courier?.phone
                          : "N/A"}
                      </p>
                      <a
                        className={`${
                          isEmptyData(
                            order?.delivery_order_details?.trackingUrl
                          ) && "hidden"
                        }`}
                        href={order?.delivery_order_details?.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tracking
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table m-0">
                      <thead className="table-light border-top">
                        <tr key={"asdasdasd"}>
                          <th>Item Name</th>
                          <th>Combo Details</th>
                          <th width="100" >Cost Before Discount (Base)</th>
                          <th width="100" >Cost After Discount (Promo)</th>
                          <th width="75">Qty</th>
                          <th width="100" className="text-end">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.order_item?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr className="table-info">
                                <td className="text-nowrap" colSpan={4}>
                                  {item.outlet_name}
                                </td>

                                <td colSpan={2} className="text-end">
                                  Status:{" "}
                                  {item.outlet_order_details.status_name}
                                </td>
                              </tr>
                              {item?.outlet_item?.map((item1, index1) => {
                                return (
                                  <tr key={index1}>
                                    <td className="text-nowrap" valign="top">
                                      <span className="fw-semibold">
                                        {item1.item_name}
                                      </span>
                                      {item1.item_specification !== "" &&
                                        item1.item_specification !== null && (
                                          <i>
                                            <br />
                                            {item1.item_specification}
                                          </i>
                                        )}
                                    </td>
                                    <td className="text-nowrap">
                                      {item1.combo_set.length && (
                                        <ul className="list-unstyled">
                                          {item1.combo_set.map(
                                            (comob, comboIndex) => {
                                              return (
                                                <li
                                                  className="mb-3"
                                                  key={comboIndex}
                                                >
                                                  <i className="mdi mdi-chevron-right scaleX-n1-rtl text-muted me-1"></i>
                                                  <span className="fw-semibold">
                                                    {comob.component_name}
                                                  </span>
                                                  {comob.component_item.length >
                                                    0 && (
                                                    <ul className="list-unstyled mx-4">
                                                      {comob.component_item.map(
                                                        (
                                                          comobItem,
                                                          comobItemIndex
                                                        ) => {
                                                          return (
                                                            <li
                                                              key={
                                                                comobItemIndex
                                                              }
                                                            >
                                                              <i className="mdi mdi-chevron-right scaleX-n1-rtl text-muted me-1"></i>
                                                              {
                                                                comobItem.menu_product_name
                                                              }{" "}
                                                              X{" "}
                                                              {
                                                                comobItem.menu_product_qty
                                                              }
                                                              {parseFloat(
                                                                comobItem.menu_product_price
                                                              ) > 0
                                                                ? "(" +
                                                                  showPriceValue(
                                                                    comobItem.menu_product_price
                                                                  ) +
                                                                  ")"
                                                                : ""}
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  )}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </td>
                                    <td valign="top">
                                      {showPriceValue(item1.product_cost)}
                                    </td>
                                    <td className="text-end" valign="top">
                                      {showPriceValue(item1.item_unit_price)}
                                    </td>
                                    <td valign="top">{item1.item_qty}</td>
                                    <td className="text-end" valign="top">
                                      {showPriceValue(item1.item_total_amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr className="table-primary" key={index + "_1"}>
                                <td className="text-nowrap" colSpan={2}></td>

                                <td colSpan={4} className="text-end">
                                  <span className="fw-semibold">
                                    Outlet Total:
                                  </span>{" "}
                                  {showPriceValue(
                                    item.outlet_order_details.outlet_grand_total_amount
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                        <tr>
                          <td className="align-top px-4 py-5">
                            <p className="mb-2">
                              <span className="me-1 fw-semibold">
                                Payment Method:
                              </span>
                              <span>{order.order_method_name}</span>
                            </p>
                            {order.order_payment_mode !== "1" && (
                              <>
                                <p className="mb-2">
                                  <span className="me-1 fw-semibold">
                                    Payment Gateway:
                                  </span>
                                  <span>
                                    {order?.order_payment_getway_type?.toUpperCase()}
                                  </span>
                                </p>
                                <p className="mb-2">
                                  <span className="me-1 fw-semibold">
                                    Payment Reference No.:
                                  </span>
                                  <span>{order.payment_order_id}</span>
                                </p>
                                <p className="mb-2">
                                  <span className="me-1 fw-semibold">
                                    Payment Status:
                                  </span>
                                  <span>
                                    {order?.order_payment_getway_status?.toUpperCase()}
                                  </span>
                                </p>
                              </>
                            )}
                            {!isEmptyData(order.order_delivary_type) && (
                              <p className="mb-2">
                                <span className="me-1 fw-semibold">
                                  Delivery Details:
                                </span>
                                <span>{order.order_delivary_type}</span>
                              </p>
                            )}
                            {!isEmptyData(
                              order?.delivery_order_details?.track?.courier
                                ?.name
                            ) && (
                              <p className="mb-2">
                                <span className="me-1 fw-semibold">
                                  Delivery Driver Name:
                                </span>
                                <span>
                                  {
                                    order?.delivery_order_details?.track
                                      ?.courier?.name
                                  }
                                </span>
                              </p>
                            )}
                            {!isEmptyData(
                              order?.delivery_order_details?.track?.courier
                                ?.phone
                            ) && (
                              <p className="mb-2">
                                <span className="me-1 fw-semibold">
                                  Delivery Driver Phone:
                                </span>
                                <span>
                                  {
                                    order?.delivery_order_details?.track
                                      ?.courier?.phone
                                  }
                                </span>
                              </p>
                            )}

                            {!isEmptyData(order?.voucher_b2b) && (
                              <p className="mb-2">
                                <span className="me-1 fw-semibold">
                                  Voucher B2B:
                                </span>
                                <span>
                                  {order?.voucher_b2b.map((item, index) => (
                                    <p>
                                      <div key={index}>
                                        <span>
                                          Denom Voucher: {item?.voucher_nominal}
                                        </span>
                                        <br />
                                        <span>
                                          Price Voucher:{" "}
                                          {item?.voucher_price_user}
                                        </span>
                                        <br />
                                        <span>
                                          Qty:{" "}
                                          {item?.voucher_qty}
                                        </span>
                                      </div>
                                    </p>
                                  ))}
                                </span>
                              </p>
                            )}
                          </td>
                          <td
                            colSpan="3"
                            className="text-end px-4 py-5"
                            valign="top"
                          >
                            {parseFloat(order.order_sub_total) > 0 && (
                              <p className="mb-2">Subtotal:</p>
                            )}
                            {parseFloat(order.order_delivery_charge) > 0 && (
                              <p className="mb-2">Delivery Charge:</p>
                            )}
                            {parseFloat(order.order_packaging_fee) > 0 && (
                              <p className="mb-2">Packaging Fee:</p>
                            )}

                            {parseFloat(order.order_platform_fee) > 0 && (
                              <p className="mb-2">Platform Fee:</p>
                            )}
                            {parseFloat(order.order_delivery_subsidies) > 0 && (
                              <p className="mb-2">Delivery Subsidies:</p>
                            )}
                            {parseFloat(order.order_additional_delivery) >
                              0 && (
                              <p className="mb-2">
                                Additional Delivery Charge:
                              </p>
                            )}
                            {parseFloat(order.order_service_charge) > 0 && (
                              <p className="mb-2">
                                {order.order_servicecharge_displaylabel !==
                                  "" &&
                                order.order_servicecharge_displaylabel !== null
                                  ? order.order_servicecharge_displaylabel
                                  : "Service Charge"}
                              </p>
                            )}
                            {(parseFloat(order.order_tax_charge) > 0 ||
                              parseFloat(
                                order.order_tax_calculate_amount_inclusive
                              ) > 0) && (
                              <p className="mb-2">
                                {parseFloat(
                                  order.order_tax_calculate_amount_inclusive
                                ) > 0
                                  ? "Inclusive Tax "
                                  : "Tax"}
                                ({order.order_service_charge}%):
                              </p>
                            )}
                            {order?.discount?.map((item, index) => {
                              return (
                                <p className="mb-2" key={index}>
                                  Discount({item.promoCode}):
                                </p>
                              );
                            })}
                            {order?.voucher?.map((item, index) => {
                              return (
                                <p className="mb-2" key={index}>
                                  Voucher({item.name}):
                                </p>
                              );
                            })}

                            <p className="mb-0">Total:</p>
                          </td>
                          <td className="px-4 py-5" colSpan={"4"} valign="top">
                            {parseFloat(order.order_sub_total) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(order.order_sub_total)}
                              </p>
                            )}{" "}
                            {parseFloat(order.order_delivery_charge) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(
                                  parseFloat(order.order_delivery_charge) +
                                    parseFloat(order.order_delivery_fee)
                                )}
                              </p>
                            )}
                            {parseFloat(order.order_packaging_fee) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(order.order_packaging_fee)}
                              </p>
                            )}
                            {parseFloat(order.order_platform_fee) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(order.order_platform_fee)}
                              </p>
                            )}
                            {parseFloat(order.order_delivery_subsidies) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(order.order_delivery_subsidies)}
                              </p>
                            )}
                            {parseFloat(order.order_service_charge) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {showPriceValue(order.order_service_charge)}
                              </p>
                            )}
                            {(parseFloat(order.order_tax_charge) > 0 ||
                              parseFloat(
                                order.order_tax_calculate_amount_inclusive
                              )) > 0 && (
                              <p className="fw-semibold mb-2 text-end">
                                {parseFloat(
                                  order.order_tax_calculate_amount_inclusive
                                ) > 0
                                  ? showPriceValue(
                                      order.order_tax_calculate_amount_inclusive
                                    )
                                  : parseFloat(order.order_tax_charge) > 0
                                  ? showPriceValue(order.order_tax_charge)
                                  : ""}
                              </p>
                            )}
                            {order?.discount?.map((item, index) => {
                              return (
                                <p
                                  className="fw-semibold mb-2 text-end"
                                  key={index}
                                >
                                  -{showPriceValue(item.promoAmount)}
                                </p>
                              );
                            })}
                            {order?.voucher?.map((item, index) => {
                              return (
                                <p
                                  className="fw-semibold mb-2 text-end"
                                  key={index}
                                >
                                  -{showPriceValue(item.amount)}
                                </p>
                              );
                            })}
                            <p className="fw-semibold mb-2 text-end">
                              {showPriceValue(order.order_total_amount)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12"></div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Details);
